import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as ApiCall from "../API/ApiCalling";
import { Box } from "@mui/system";
import useCrmVariable from "../../CustomHook/useCrmVariable";
import {
  Autocomplete,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import useField from "../../CustomHook/useField";
import ModuleSetting from "./ModuleSetting";

const styles = createStyles({
  typography: {
    lineHeight: 2,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function ModulesRelatedFieldSetting({ module = "" }) {
  const [moduleRelatedList, setModuleRelatedList] = React.useState({});
  const [editData, setEditData] = React.useState("");
  const { orgid, connname, apikey, zuid, datacenterurl } = useCrmVariable({});
  const { admin, fields } = useField(module);
  React.useEffect(() => {
    async function fetchRelatedList(params) {
      try {
        const moduleList = await ApiCall.getSettingData(
          "",
          "relatedList",
          connname,
          orgid,
          apikey,
          datacenterurl
        );

        const tempRelatedData = {};

        moduleList?.data?.forEach((setting, index) => {
          const setting_schema = JSON.parse(setting?.setting_schema);
          if (index <= 2) {
            tempRelatedData[setting?.setting_id] = {
              ...setting,
              moduleApiName: setting_schema?.moduleApiName,
              fieldName: setting_schema?.fieldApiName?.display_name,
            };
          } else {
            tempRelatedData[setting?.setting_id] = {
              ...setting,
              moduleApiName: setting_schema?.moduleApiName,
              fieldName: setting_schema?.fieldApiName?.display_name,
            };
          }
        });
        setModuleRelatedList((prev) => tempRelatedData);
      } catch (error) {}
    }
    fetchRelatedList();
  }, []);
  // TODO: make the json as object. otherwise it falls in infinite loop.

  return (
    <Box
      sx={{
        py: 1,
        // width: "85%",
        mx: "auto",
        mt: 2,
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Related List Settings
        </Typography>

        <Box sx={{ my: 1, width: "100%" }}>
          <TableContainer>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "#b5b5b5" }}>
                  <TableCell sx={{ border: "none", width: 300 }}>
                    <Typography align="left" sx={{ fontWeight: "medium" }}>
                      Module
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography
                      align="left"
                      sx={{ ml: 2, fontWeight: "medium" }}
                    >
                      Field
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <>{JSON.stringify({ moduleRelatedList })}</> */}
                {Object.keys(moduleRelatedList).length > 0 ? (
                  Object.keys(moduleRelatedList).map((settingID, index) => (
                    <>
                      <ModuleSetting
                        bgcolor={index % 2}
                        key={settingID}
                        row={moduleRelatedList[settingID]}
                        index={settingID}
                        moduleRelatedList={moduleRelatedList}
                        setModuleRelatedList={setModuleRelatedList}
                        editData={editData}
                        setEditData={setEditData}
                      />
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{ border: "none", height: 200 }}
                    >
                      <CircularProgress size={28} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}
