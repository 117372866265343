import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import ButtonGroup from "@mui/material/ButtonGroup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Link from "@mui/material/Link";
import ShareIcon from "@mui/icons-material/Share";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
// import "../../../App.css";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import IconButton from "@mui/material/IconButton";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";

import ClipboardCopy from "../Modals/ClipboardCopy";
import NameDialog from "../Others/NameDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormControl } from "react-bootstrap";
import SyncIcon from "@mui/icons-material/Sync";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

const Input = styled("input")({
  display: "none",
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

//search box component starts
let snackMessage = {
  bool: true,
  type: "error",
  message: "Request failed",
};
//search box component ends

const useStyles = makeStyles({
  breadcumbstyle: {
    // fontSize: '20px ',
  },
  grid: {
    // backgroundColor: "#F1F4F7",
    alignItems: "center",
    borderBottom: "1px solid #efefef",
    paddingLeft: "12px",
    display: "flex",
    // paddingBottom: 8,
  },
  button: {
    backgroundColor: "#11BD97",
    height: "70%",
    margin: "auto",
    marginRight: 0,
    boxShadow: "none",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#0E997B",
      boxShadow: "none",
    },
  },
  label: {
    color: "red",
  },
});

let myBread = [];

const globalView = [
  {
    name: "list",
    icon: <ViewCompactIcon sx={{ fontSize: 18, mr: 0 }} />,
  },
  {
    name: "grid",
    icon: <GridViewIcon sx={{ fontSize: 18, mr: 0 }} />,
  },
  {
    name: "details",
    icon: <ViewHeadlineIcon sx={{ fontSize: 18, mr: 0 }} />,
  },
];
export default function CustomSeparator({
  setSearchOpen,
  setSearchedItem,
  Entity,
  EntityId,
  fieldApiName,
  setFieldApiName,
  setRootFolderId,
  recordData,
  fields,
  isAdmin,
  settingId,
  setBreadCrumbsUrl,
  orgid,
  connname,
  apikey,
  datacenterurl,
  settingUrl,
  setSearchVal,
  searchVal,
  setPost,
  post,
  setSnackOpen,
  widthSM,
}) {
  const snap = useSnapshot(widState);
  const classes = useStyles();

  const fileInputRef = React.useRef(null);
  const anchorRef = React.useRef(null);

  const handleClickFile = () => {
    fileInputRef.current.click();
  };

  const [open, setOpen] = React.useState(false);
  const [openSettingOptions, setOpenSettingOptions] = React.useState(false);
  const [name, setName] = React.useState("");
  const [apiSearch, setApiSearch] = React.useState("");

  const [sharedFileOpen, setSharedOpen] = React.useState(false);
  const [sharedLink, setSharedLink] = React.useState("");
  const [value, setValue] = React.useState(
    fields?.filter((field) => field?.api_name == fieldApiName)?.[0]
  );
  // const theme = useTheme();
  // const upsm = useMediaQuery(theme.breakpoints.up("sm"));
  // console.log({ matches: upsm });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSharedOpen((prev) => false);
  };

  const handleDrop = (event, file) => {
    event.preventDefault();
    var moveData = JSON.parse(event.dataTransfer.getData("moveData"));
    if (moveData?.dropFile && moveData?.dropFile !== file?.id) {
      snap.setLoading(true);
      let tempView = snap?.view;
      // if (tempView) {
      //   snap.setViewSettingData(settingId, false);
      // }
      ApiCall.moveFile(
        file,
        {
          id: moveData?.dropFile,
          parent_id: moveData?.dropFileParentId,
        },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      )
        .then((response) => {
          // alert(JSON.stringify(response));
          // // ;
          let xArray = post.filter((file) => file.id != moveData?.dropFile);
          // setSnackOpen(true)
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "Item moved successfully",
          });

          // let lastIndex =
          //   snap?.settingData?.[settingId]?.breadCrumbs?.length - 1;
          // let lastIndexId =
          //   snap?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
          widState?.setAddItemSettingData(
            settingId,
            moveData?.dropFileParentId,
            xArray
          );

          if (
            widState?.settingData?.[settingId]?.previousData?.hasOwnProperty(
              `${file?.id}`
            )
          ) {
            let tempArray = JSON.parse(
              JSON.stringify(
                widState?.settingData?.[settingId]?.previousData?.[file?.id]
              )
            );
            tempArray = tempArray.concat([response?.data]);
            // // ;
            widState?.setAddItemSettingData(settingId, file?.id, tempArray);
            // snap?.setApiSettingData(settingId, file?.id, tempArray);
          }
          setPost(xArray);
          // if (tempView) {
          //   snap.setViewSettingData(settingId, true);
          // }
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "File moved successfully",
          });
          event.dataTransfer.setData("moveData", null);

          snap.setLoading(false);
        })
        .catch((error) => {
          // if (tempView) {
          //   snap.setViewSettingData(settingId, true);
          // }
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: "Error!! Couldn't move file",
          });

          snap.setLoading(false);
        });
    }
  };

  const searchGlobally = async () => {
    snap.setLoading(true);
    let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
    let lastIndexId =
      snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
    handleMenuClose();
    snap.setLoading(true);
    try {
      const searchedFile = await ApiCall.searchInsideFolder(
        lastIndexId,
        "*",
        connname,
        orgid,
        apikey,
        datacenterurl
      );

      setSearchedItem((prev) => searchedFile?.data);
      setSearchOpen((prev) => true);
    } catch (error) {}

    snap.setLoading(false);
    return;
  };

  function titleCase(str) {
    return str?.toLowerCase()?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  //Bread crumb
  myBread = snap.settingData?.[settingId]?.breadCrumbs?.map((file, index) => {
    if (index == snap.settingData?.[settingId]?.breadCrumbs?.length - 1) {
      return (
        <Typography key="3" sx={{ fontSize: "16px", color: "#000000" }}>
          {file?.name || ""}
        </Typography>
      );
    } else {
      return (
        <Link
          underline="none"
          key={index}
          color="inherit"
          href="#"
          id={file?.id}
          onDrop={(e) => handleDrop(e, file)}
          onClick={() => {
            // TODO: Check folder Data exist or not

            setBreadCrumbsUrl(
              file,
              widState?.settingData?.[settingId]?.previousData?.[file?.id]
            );
          }}
        >
          <p
            style={{
              fontSize: "16px",
            }}
          >
            {/* {titleCase(file?.name || '')} */}
            {file?.name || ""}
          </p>
        </Link>
      );
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dropDownmeuopen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSettingOptions(true);
  };

  const handleMenuClose = () => {
    setAnchorEl((prev) => null);
  };

  const handleSyncAttachment = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      snap.setLoading(true);

      handleMenuClose();
      let data = { recordId: EntityId, moduleName: Entity };

      const fetchAttahment = await ApiCall.syncAttachment(
        lastIndexId,
        data,
        connname,
        orgid,
        apikey,
        datacenterurl
      );
      let tempData = [...post, ...fetchAttahment?.data];
      setPost((prev) => tempData);
      widState?.setAddItemSettingData(settingId, lastIndexId, tempData);
      snap.setLoading(false);
      snap.setSnackbar({
        bool: true,
        type: "success",
        message: fetchAttahment?.message,
      });
    } catch (error) {}
  };

  const handleGoToWorkdrive = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      handleMenuClose();
      snap.setLoading(true);
      window.open(
        `https://workdrive.zoho.${
          widState.dataCenterUrl.split("https://www.zohoapis.")?.[1]
        }/folder/` +
          lastIndexId +
          "?layout=list",
        "_blank",
        "noreferrer"
      );
      snap.setLoading(false);
    } catch (error) {}
  };

  const handleRefresh = async () => {
    try {
      snap.setLoading(true);
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      handleMenuClose();
      widState.setDeleteThumbNail(settingId, post);
      // widState?.setResetData(settingId, lastIndexId);
      try {
        let res = await ApiCall.getFoldersItem(
          lastIndexId,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (res?.error) {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: res?.error,
          });
        } else {
          setPost([]);
          setPost(res?.data);
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "Data refreshed successfully",
          });
          widState?.setRefreshData(settingId, lastIndexId, res?.data);
        }
      } catch (error) {
        snap.setSnackbar({
          bool: true,
          type: "error",
          message: error,
        });
      }
      snap.setLoading(false);
    } catch (error) {
      snap.setSnackbar({
        bool: true,
        type: "error",
        message: error.toString(),
      });
    }
  };

  const handleShareFolderDownloadOnly = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      snap.setLoading(true);

      const response = await ApiCall.shareFileFolder(
        {},
        { id: lastIndexId },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      if (response?.error) {
        // ;
        snackMessage = {
          bool: true,
          type: "error",
          message: response?.error?.errors?.[0]?.title,
        };
        snap.setSnackbar(snackMessage);
        snap.setLoading(false);
        return;
      }

      snackMessage = {
        bool: true,
        type: "success",
        message: "File shared successfully",
      };

      setSharedLink((prev) => response?.data?.data?.attributes?.link);
      setSharedOpen((prev) => true);

      snap.setSnackbar(snackMessage);
      snap.setLoading(false);
    } catch (error) {}
  };

  const handleShareFolderFullAccess = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      snap.setLoading(true);

      let snackMessage = {
        bool: true,
        type: "error",
        message: "Request failed",
      };

      const response = await ApiCall.shareFullAccess(
        {},
        { id: lastIndexId },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      if (response?.error) {
        snackMessage = {
          bool: true,
          type: "error",
          message: response?.error?.errors?.[0].title,
        };
        snap.setSnackbar(snackMessage);
        snap.setLoading(false);
        return;
      }

      snackMessage = {
        bool: true,
        type: "success",
        message: "File shared successfully",
      };

      setSharedLink((prev) => response?.data?.data?.attributes?.link);
      setSharedOpen((prev) => true);

      snap.setSnackbar(snackMessage);

      snap.setLoading(false);
    } catch (error) {
      snackMessage = {
        bool: true,
        type: "error",
        message: error,
      };
      snap.setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", px: "20px", borderBottom: "1px solid #D2D9E0" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Breadcrumbs
            sx={{ "& p": { my: "6.5px" } }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            // className={classes.breadcumbstyle}
          >
            {myBread}
          </Breadcrumbs>
        </Stack>

        <Box
          sx={{
            // bgcolor: "red",
            ...(widthSM
              ? {
                  position: "absolute",
                  bottom: ".5rem",
                  right: 0,
                  left: 0,
                  //-------
                  display: "flex",
                  justifyContent: "center",
                }
              : null),
          }}
          // sx={{
          //   bgcolor: "green",
          //   ...(widthSM
          //     ? {
          //         margin: "0 auto",
          //         width: "fit-content",
          //       }
          //     : null),

          //   display: "flex",
          //   alignItems: "center",
          //   marginLeft: "auto",
          //   //-------------------
          //   // backgroundColor: "green",
          // }}
        >
          <ButtonGroup
            ref={anchorRef}
            variant="outlined"
            aria-label="outlined button group"
            size="small"
          >
            <Button
              sx={{ pl: "10px" }}
              aria-label="search"
              onClick={searchGlobally}
              size="small"
            >
              <SearchIcon sx={{ fontSize: 20 }} />
            </Button>
            <Button
              size="small"
              // variant="contained"
              // component="label"
              startIcon={<AddIcon sx={{ fontSize: 18 }} />}
              onClick={handleClickOpen}
              sx={{
                // height: "35px",
                // padding: 1,
                // borderRadius: "20px",
                // bgcolor: "#1976D2",
                width: 100,
                // fontSize: "14px",
              }}
            >
              Folder
            </Button>
            {/* <Button
              onChange={async (e) => {
                try {
                  const myFiles = Array.from(e?.target?.files);
                  if (!myFiles?.length) {
                    return;
                  }
                  // const data = new FormData();
                  // data.append("file", e.target.files[0]);
                  const data = new FormData();
                  myFiles.forEach((file) => {
                    data.append("file", file);
                  });

                  let lastIndex =
                    snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
                  let lastIndexId =
                    snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
                  if (myFiles?.length) {
                    snap.setLoading(true);
                  }
                  let tempView = snap?.view;
                  // if (tempView) {
                  //   snap.setViewSettingData(settingId, false);
                  // }

                  // snap.setLoading(true);
                  let response = await ApiCall.fileUploader(
                    data,
                    lastIndexId,
                    connname,
                    orgid,
                    apikey,
                    datacenterurl,
                    settingUrl
                  );
                  let myCustomArray = [...response?.data, ...post];
                  setSnackOpen((prev) => true);

                  snap.setSnackbar({
                    bool: true,
                    type: "success",
                    message: "File uploaded successfully",
                  });

                  snap.setAddItemSettingData(
                    settingId,
                    lastIndexId,
                    myCustomArray
                  );
                  setPost((prev) => myCustomArray);
                  // if (tempView) {
                  //   snap.setViewSettingData(settingId, true);
                  // }
                  snap.setLoading(false);
                } catch (error) {
                  snap.setLoading(false);
                }
              }}
              component="label"
              size="small"
              role={undefined}
              // variant="contained"
              tabIndex={-1}
              sx={{ borderRadius: 0 }}
            >
              <FileUploadOutlinedIcon />
              <VisuallyHiddenInput type="file" />
            </Button> */}

            <Input
              id="icon-button-file"
              type="file"
              multiple={true}
              name="file"
              ref={fileInputRef}
              onChange={async (e) => {
                try {
                  const myFiles = Array.from(e?.target?.files);
                  if (!myFiles?.length) {
                    return;
                  }
                  // const data = new FormData();
                  // data.append("file", e.target.files[0]);
                  const data = new FormData();
                  myFiles.forEach((file) => {
                    data.append("file", file);
                  });

                  let lastIndex =
                    snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
                  let lastIndexId =
                    snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
                  if (myFiles?.length) {
                    snap.setLoading(true);
                  }
                  let tempView = snap?.view;
                  // if (tempView) {
                  //   snap.setViewSettingData(settingId, false);
                  // }

                  // snap.setLoading(true);
                  let response = await ApiCall.fileUploader(
                    data,
                    lastIndexId,
                    connname,
                    orgid,
                    apikey,
                    datacenterurl,
                    settingUrl
                  );
                  let myCustomArray = [...response?.data, ...post];
                  setSnackOpen((prev) => true);

                  snap.setSnackbar({
                    bool: true,
                    type: "success",
                    message: "File uploaded successfully",
                  });

                  snap.setAddItemSettingData(
                    settingId,
                    lastIndexId,
                    myCustomArray
                  );
                  setPost((prev) => myCustomArray);
                  // if (tempView) {
                  //   snap.setViewSettingData(settingId, true);
                  // }
                  snap.setLoading(false);
                } catch (error) {
                  snap.setLoading(false);
                }
              }}
            />
            <Button
              variant="outlined"
              htmlFor="icon-button-file"
              onClick={handleClickFile}
              // aria-label="upload picture"
              // component="span"
              // size="large"
              sx={{ borderRadius: "20px", mx: 1.2, height: "35px" }}
            >
              <FileUploadOutlinedIcon sx={{ fontSize: 18 }} />{" "}
              {/* <Typography sx={{ fontSize: 16 }}>Upload</Typography> */}
            </Button>
            {globalView.map((button, index) => {
              return (
                <Button
                  variant={
                    snap?.view == button?.name ? "contained" : "outlined"
                  }
                  onClick={() => {
                    widState.setView(button?.name);
                  }}
                  // sx={{ borderRadius: "20px" }}
                >
                  {button?.icon}
                </Button>
              );
            })}
            <Button
              aria-label="Settings icon"
              aria-haspopup="menu"
              size="small"
              onClick={handleClick}
            >
              <SettingsIcon sx={{ fontSize: 20 }} />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={openSettingOptions}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenSettingOptions(false);
                    }}
                  >
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem
                        sx={{
                          fontSize: "15px",
                          color: "#1976D2",
                        }}
                        onClick={handleSyncAttachment}
                      >
                        <SyncIcon sx={{ fontSize: "15px", mr: 1 }} />
                        Sync Attachments
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "15px",
                          color: "#1976D2",
                        }}
                        role="link"
                        onClick={handleRefresh}
                      >
                        <OpenInNewIcon sx={{ fontSize: "15px", mr: 1 }} />
                        Refresh
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "15px",
                          color: "#1976D2",
                        }}
                        role="link"
                        onClick={handleGoToWorkdrive}
                      >
                        <OpenInNewIcon sx={{ fontSize: "15px", mr: 1 }} />
                        Go to WorkDrive Folder
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "15px",
                          color: "#1976D2",
                        }}
                        role="link"
                        onClick={handleShareFolderDownloadOnly}
                      >
                        <SendAndArchiveIcon sx={{ fontSize: "15px", mr: 1 }} />
                        Share Current Folder(Download Only)
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "15px",
                          color: "#1976D2",
                        }}
                        role="link"
                        onClick={handleShareFolderFullAccess}
                      >
                        <ShareIcon sx={{ fontSize: "15px", mr: 1 }} />
                        Share Current Folder(Full Access)
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>

      <NameDialog
        settingId={settingId}
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        setPost={setPost}
        post={post}
      />
      <Dialog
        open={sharedFileOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // maxWidth: "400px", // Set your width here
              padding: "10px",
              wordWrap: "break-word",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{ padding: "15px", backgroundColor: "#001E3C", color: "#fff" }}
          >
            <Grid xs={11}>{sharedLink}</Grid>
            <Grid xs={1} sx={{ paddingLeft: "5px" }}>
              <ClipboardCopy copyText={sharedLink} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
