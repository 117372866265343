import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function Overview({
  authCheck,
  authLoading,
  setDataCenterUrl,
  handleAuthenticate,
  dataCenterUrl,
  handleRevoke,
}) {
  const dataCenterUrlOptions = [
    "https://accounts.zoho.com",
    "https://accounts.zoho.eu",
    "https://accounts.zoho.com.au",
    "https://accounts.zoho.in",
    "https://accounts.zoho.com.cn",
    "https://accounts.zoho.jp",
  ];
  // ;
  // const [disable, setDisable] = useState(!dataCenterUrl);
  return (
    <Box
      sx={{
        py: 1,
        // width: "85%",
        mx: "auto",
        mt: 2,
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Select Data Center URL
        </Typography>
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Based on different Zoho Data Center you need to select appropriate
          Data Center URL and Hit Authenticate and follow the steps to proceed.
        </Typography>
        <Box sx={{ pb: 3 }} />

        <Box>
          {!authCheck && (
            <Button
              disabled={
                authCheck ||
                !dataCenterUrl?.includes("https://www.zohoapis") ||
                authLoading
              }
              onClick={() => {
                handleAuthenticate();
              }}
              sx={{
                mb: 2,
                textTransform: "none",
                width: "11rem",
                borderRadius: "8px",
                height: "2.9rem",
                bgcolor: "#1976D2",
              }}
              variant="contained"
            >
              {authLoading ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Verifying{" "}
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
                </Box>
              ) : (
                "Authenticate"
              )}
            </Button>
          )}
          {authCheck && (
            <Button
              disabled={!authCheck}
              onClick={() => {
                handleRevoke();
              }}
              sx={{
                mb: 2,
                textTransform: "none",
                width: "11rem",
                borderRadius: "8px",
                height: "2.9rem",
                bgcolor: "#1976D2",
              }}
              variant="contained"
            >
              {authLoading ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Revoking...{" "}
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
                </Box>
              ) : (
                "Revoke"
              )}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
