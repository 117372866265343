import React, { useState, useRef, useEffect } from "react";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import getCaretCoordinates from "./Functions/getCaretCorordinate";

function MuiTextField({
  moduleFields,
  savedData,
  initialValue
}) {
  return (
    <>
      <Autocomplete
        autoHighlight={true}
        size="small"
        options={moduleFields?.filter(
          (option) => option?.type === "text" || option?.type === "textarea"
        )}
        isOptionEqualToValue={(option, value) =>
          option?.fieldApiName === value?.fieldApiName
        }
        groupBy={(option) => option?.lookupDisplayLabel}
        getOptionLabel={(option) => option?.fieldDisplayLabel}
        sx={{ width: 300, padding: "10px" }}
        value={initialValue}
        onChange={(e, value) => {
          console.log({ e, value });
          savedData(value);
          // setTextAreaValue((prev) => value);
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </>
  );
}

export default MuiTextField;
