import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import PropTypes from "prop-types";
import IndividualModuleSetting from "./IndividualModuleSetting";
import { defaultModulesWithCron } from "../../Others/Tools/GenerateRandomID";
import CronLIst from "../CronList/CronLIst";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ModuleDefaultSetting({
  orgid,
  connname,
  apikey,
  zapikey,
  datacenterurl,
  zuid,
}) {
  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          // textColor="secondary"
          // indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {defaultModulesWithCron
            ?.filter((el) => el !== "Cron")
            ?.map((module, index) => (
              <Tab key={module} label={module} {...a11yProps(index)} />
            ))}

          {defaultModulesWithCron
            ?.filter((el) => el === "Cron")
            ?.map((module, index) => (
              <Tab
                key={module}
                label={" Historical Data "}
                {...a11yProps(index)}
                sx={{
                  bgcolor: "rgba(25,118,210,.9)",
                  color: "#fff !important",
                  boxShadow: "inset 0px 0px 0px 7px rgba(255,255,255,1)",
                  ml: "auto",
                }}
              />
            ))}
        </Tabs>
      </Box>

      {defaultModulesWithCron?.map((module, index) => {
        if (index === 5 && value === index) {
          return (
            <CronLIst
              key={module}
              value={value}
              index={index}
              module={module}
              orgid={orgid}
              connname={connname}
              apikey={apikey}
              zapikey={zapikey}
              datacenterurl={datacenterurl}
              zuid={zuid}
            />
          );
        } else if (value === index) {
          return (
            <IndividualModuleSetting
              sx={{ pt: 2, px: 2 }}
              key={module}
              value={value}
              index={index}
              module={module}
              orgid={orgid}
              connname={connname}
              apikey={apikey}
              zapikey={zapikey}
              datacenterurl={datacenterurl}
              zuid={zuid}
            />
          );
        }
        return <></>;
      })}
    </Box>
  );
}

export default ModuleDefaultSetting;
