import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import * as ApiCall from "../../API/ApiCalling";
import { widState } from "../../../Store/workdriveState";
import MySortableTree, {
  baseTreeData,
} from "../../Others/SortableTree/MySortableTree";
import SnackAlert from "../../RelatedList/srcfolder/components/WorkDrive/Others/SnackAlert";
import ModuleHistoricData from "./ModuleHistoricData";

const ZOHO = window.ZOHO;
function ProcessHistoricDataNew(props) {
  const {
    zapikey,
    orgid,
    connname,
    apikey,
    datacenterurl,
    zuid,
    handleClose,
    toggle,
    setToggle,
  } = props;

  const snap = useSnapshot(widState);
  const [loading, setLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [customViewList, setCustomViewList] = useState([]);
  const [rootFolder, setRootFolder] = useState({});
  const [customSettingData, setCustomSettingData] = useState({});
  const [module, setModule] = useState({});
  const [customView, setCustomView] = useState({});
  const [folderField, setFolderField] = useState({});

  const [isValid, setValid] = useState(false);
  const [treeData, setTreeData] = useState(baseTreeData);

  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [step, setStep] = useState(0);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const saveTreeData = (data) => {
    const { key, response } = data;
    setTreeData((prev) => response);
  };

  const createCronJob = async ({ executeConfigSettingId }) => {
    const { workspace, teamFolder, rootFolder, folderField, treeData } =
      customSettingData;
    console.log({
      workspace,
      teamFolder,
      rootFolder,
      folderField,
      treeData,
      module,
      customView,
    });

    const data = {
      reqUrl: encodeURIComponent(
        process.env.REACT_APP_API_SERVER_URL +
          "/cron/execute/newfolderstructure"
      ),
      reqParams: {},
      reqHeaders: {
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        "content-type": "application/json",
        datacenterurl: datacenterurl,
      },
      reqBody: {
        funcType: "SaveRecordIds",
        moduleApiName: module?.apiName,
        module,
        customView,
        executeConfigSettingId: executeConfigSettingId,
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        datacenterurl: datacenterurl,
        zapikey: zapikey,
        willSkip: true,
        page: 1,
        perPage: 5,
      },
      reqType: "GET",
    };

    console.log({ data });
    // 1003.c64cad88655c146dbfd83a105d75d67a.083b155e009561e91078b15457940238
    // console.log({ customViewData: data });

    try {
      const createJob = await ApiCall.createCronJob(data);
      if (createJob?.data) {
        // setMessage("Created Successfully..");
        // setSeverity("success");
        // setOpen(true);
        handleClose();
        setToggle(!toggle);
      } else {
        console.log({ error: createJob?.error });
        setMessage(createJob?.error);
        setSeverity("error");
        setOpen(true);
      }
      // console.log({ createJob: createJob?.data });
    } catch (error) {
      console.log("error" + error);
    }
  };

  const handleCreateCronSetting = async (event) => {
    const { workspace, teamFolder, rootFolder, folderField, treeData } =
      customSettingData;
    console.log({
      workspace,
      teamFolder,
      rootFolder,
      folderField,
      treeData,
      module,
      customView,
    });

    const settingSchema = {
      newFolderStructure: true,
      moduleApiName: module?.apiName,
      module,
      customView,
      workspace,
      teamFolder,
      rootFolder,
      folderField,
      treeData,
    };

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingSchema: settingSchema,
        showInRelatedList: false,
        moduleApiName: module?.apiName,
        settingType: "executeConfigForCron",
      },
      headers: {
        orgid: orgid,
        apikey: apikey,
        datacenterurl: datacenterurl,
        connname: "easyworkdriveforcrm",
      },
    });

    const executeConfigSettingId = insertedData?.data?.data?.setting_id || "";

    if (executeConfigSettingId !== "") {
      await createCronJob({ executeConfigSettingId });
    }
  };

  async function getCustomView(module) {
    if (!module?.apiName) {
      setCustomViewList((prev) => []);
      return;
    }
    const { displayName: moduleDisplayName, apiName: moduleApiName } = module;
    try {
      const customViewResp = await ZOHO.CRM.META.getCustomViews({
        Entity: moduleApiName,
      });
      const customViews = customViewResp?.custom_views?.map((cv) => {
        return { name: cv?.name, id: cv?.id };
      });
      setCustomViewList((prev) => customViews);
    } catch (error) {}
  }

  useEffect(() => {
    async function getModules() {
      ZOHO.CRM.META.getModules().then(function (data) {
        let modules = data?.modules
          ?.filter((module) => module?.isBlueprintSupported)
          ?.map((module) => {
            return {
              displayName: module.plural_label,
              apiName: module.api_name,
            };
          });
        // let filteredModule = modules?.filter(
        //   (element) => !inProgressModuleList.includes(element)
        // );
        setModuleList(modules);
      });
    }
    getModules();
  }, []);

  useEffect(() => {
    if (step == 1) {
      setValid((prev) => false);
    } else if (step == 2) {
      // console.log({ settingData: widState?.settingData?.[module?.apiName] });

      // setTreeData((prev) => widState?.settingData?.[module?.apiName]?.treeData);
      // setRootFolder((prev) => widState?.settingData?.[module?.apiName]?.rootFolder);
      // fix (workspace, team, rootFolder id) or fieldapiName and folderstructure
      if (true) {
        setValid(true);
      }
    }
  }, [step]);

  useEffect(() => {
    getCustomView(module);
    setCustomView((prev) => null);
    setFolderField((prev) => null);
    setTreeData((prev) => null);
    setRootFolder((prev) => null);
  }, [module?.apiName]);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#FAF9F6",
          p: "2rem",
          overflowY: "auto",
          maxHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", gap: "2rem" }}>
          <Box>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Select Module
            </Typography>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={
                module?.apiName
                  ? {
                      displayName: module.displayName,
                      apiName: module.apiName,
                    }
                  : null
              }
              options={moduleList}
              getOptionLabel={(option) => option.displayName}
              onChange={(error, value) => {
                if (value) {
                  setModule((prev) => {
                    return {
                      displayName: value.displayName,
                      apiName: value.apiName,
                    };
                  });

                  widState.setSelectedSetting(value.apiName);
                  setStep((prev) => 1);
                } else {
                  setCustomSettingData((prev) => {});
                  setModule((prev) => {});
                  setStep((prev) => 0);
                }
              }}
              size="small"
              sx={{ width: 550, my: ".5em" }}
              renderInput={(params) => <TextField {...params} />}
            />
            {/* <Typography sx={{ color: "#878787", fontSize: 14 }}>
              Select Field <span>(Where you want to save folder id)</span>
              <span>
                (Fields present in
                {customView?.name ? ` '${customView?.name}'` : ""} custom view
                criteria will not be available)
              </span>
            </Typography> */}
          </Box>

          <Box>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Select Custom View
            </Typography>

            <Autocomplete
              disablePortal
              // disabled={step == 1 && customViewList?.length ? false : true}
              id="combo-box-demo"
              value={
                customView?.id
                  ? {
                      name: customView?.name,
                      id: customView?.id,
                    }
                  : null
              }
              options={customViewList}
              getOptionLabel={(option) => option.name}
              onChange={(error, value) => {
                if (value) {
                  setCustomView((prev) => {
                    return {
                      name: value?.name,
                      id: value?.id,
                    };
                  });

                  // setCustomSettingData(
                  //   (prev) => widState?.settingData?.[module?.apiName] || {}
                  // );

                  // setTreeData(
                  //   (prev) =>
                  //     widState?.settingData?.[module?.apiName]?.treeData
                  // );

                  setStep((prev) => 2);
                } else {
                  setCustomView((prev) => {});
                  setStep((prev) => 1);
                }
              }}
              size="small"
              sx={{ width: 550, my: ".5em" }}
              renderInput={(params) => <TextField {...params} required />}
            />
            {/* <Typography sx={{ color: "#878787", fontSize: 14 }}>
              Select Field <span>(Where you want to save folder id)</span>
              <span>
                (Fields present in
                {customView?.name ? ` '${customView?.name}'` : ""} custom view
                criteria will not be available)
              </span>
            </Typography> */}
          </Box>
        </Box>
        {step >= 2 && (
          <ModuleHistoricData
            key={module.apiName}
            index={0}
            module={module.apiName}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            zuid={zuid}
            settingSchema={customSettingData}
            setSettingSchema={setCustomSettingData}
          />
        )}

        <Box>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ mr: 2 }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            variant="contained"
            onClick={handleCreateCronSetting}
            sx={{ width: 220 }}
            size="small"
          >
            Create Cron Job
            {loading && (
              <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
            )}
          </Button>
        </Box>
      </Box>
      <SnackAlert
        duration={7000}
        snackOpen={open}
        handleCloseSnack={handleCloseSnackbar}
        severity={severity}
        message={message}
      />
    </>
  );
}

export default ProcessHistoricDataNew;
