import React from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";

function Loader({ message, height }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: height ?? "100vh",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>{message ?? "Please Wait While Fetching Data..."}</Typography>
      <CircularProgress sx={{ mt: 1 }} size={26} />
    </Box>
  );
}

export default Loader;
