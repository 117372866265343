import React, { useEffect, useRef, useState } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
  changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SelectFromModuleFields from "./SelectFromModuleFields";
// import { useSnapshot } from "valtio";
// import { widState } from "../../../Store/workdriveState";
import { getModuleAndLookupDataAndFields } from "../DynamicHashTextField/Functions/helperFunctions";
import { Box, Button, Modal, Typography } from "@mui/material";
import { set } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "./MySortableTree.css";
import EditModalNode from "./EditModalNode";
import CreateModalNode from "./CreateModalNode";
import RemoveModalNode from "./RemoveModalNode";
import { getInitialFilteredOptions } from "./OtherFunctions";

export const baseTreeData = [
  {
    title: "Folder Name",
    information: {
      shareUrl: {
        isChecked: null,
      },
      saveFolder: {
        isChecked: null,
      },
      copyLeadFolder: {
        isChecked: false,
      },
      usePreviousFolder: {
        isChecked: null,
      },
      fullAccessShareUrl: {
        isChecked: null,
      },
      moveFolderIfExist: {
        isChecked: null,
      },
    },
    id: 0,
    expanded: true,
  },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ZOHO = window.ZOHO;

function MySortableTree({
  moduleName,
  myTreeData = baseTreeData,
  savedData,
  allowMultipleRoots = false,
  previousConfig = false,
}) {
  const [currentNode, setCurrentNode] = useState(null);
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [fieldNames, setFieldNames] = useState([]);
  const [leadFields, setLeadFields] = useState([]);

  const [deleteNodeDetails, setDeleteNodeDetails] = useState({});

  const [open, setOpen] = useState(false);
  let row = useRef({});

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setDeleteNodeDetails({});
    setModalOpen(false);
  };

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const [createModalOpen, setCreateModalOpen] = useState(!previousConfig);
  const handleCreateModalOpen = () => setCreateModalOpen(true);
  const handleCreateModalClose = () => setCreateModalOpen(false);

  const handleNodeDelete = () => {
    const myData = removeNodeAtPath(deleteNodeDetails);

    savedData({
      key: "treeData",
      response: myData,
    });

    handleModalClose();
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {});

      await ZOHO.embeddedApp.init();
      setZohoLoaded(true);
    }
    initZoho();
  }, []);

  // useEffect(() => {
  //   setTreeData(widState?.settingData?.[widState?.selectedSetting]?.treeData);
  // }, [widState?.selectedSetting]);

  useEffect(() => {
    async function fetchData() {
      if (moduleName && zohoLoaded && moduleName !== "") {
        async function getRecordAndFieldData() {
          try {
            setFieldNames((prev) => []);
            const entityDataAndFields = await getModuleAndLookupDataAndFields(
              moduleName
            );
            let allFieldsData = [];
            Object.keys(entityDataAndFields?.fieldNames).forEach((module) => {
              allFieldsData = [
                ...allFieldsData,
                ...entityDataAndFields?.fieldNames[module],
              ];
            });

            const leadDataAndFields = await getModuleAndLookupDataAndFields(
              "Leads"
            );
            let leadFieldsData = [
              ...leadDataAndFields?.fieldNames?.Leads?.filter(
                (field) => field?.type == "text" || field?.type == "textarea"
              ),
            ];
            console.log({ leadFieldsData });
            setLeadFields((prev) => leadFieldsData);
            setFieldNames((prev) => allFieldsData);
            savedData({ key: "treeData", response: myTreeData });
          } catch (error) {}
        }
        getRecordAndFieldData();
      }
    }
    fetchData();
  }, [moduleName, zohoLoaded]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  if (!zohoLoaded) {
    return <>Zoho is not loaded yet</>;
  }

  return (
    // <div style={{ height: 800 }}>
    <Box sx={{ height: 300, "& .MuiFormControl-root": { width: "350px" } }}>
      <SortableTree
        className={
          myTreeData?.length > 1
            ? ""
            : myTreeData?.[0]?.children && myTreeData?.[0]?.children?.length
            ? ""
            : "oneChild"
        }
        treeData={myTreeData}
        onChange={(treeData) => {
          if (!allowMultipleRoots && treeData?.length == 1) {
            return savedData({ key: "treeData", response: treeData });
          } else if (!allowMultipleRoots && treeData?.length >= 2) {
            return setOpen(true);
          }
          savedData({ key: "treeData", response: treeData });
        }}
        generateNodeProps={({ node, path, treeIndex }) => ({
          title: (
            <SelectFromModuleFields
              moduleFields={fieldNames}
              leadFields={leadFields}
              node={node}
              path={path}
              myTreeData={myTreeData}
              getNodeKey={getNodeKey}
              savedData={savedData}
            />
          ),
          buttons:
            node?.id !== 0
              ? [
                  <AddCircleIcon
                    sx={{
                      color: "#1976d2",
                      "&:hover": { cursor: "pointer", color: "#1565c0" },
                    }}
                    onClick={() => {
                      setCurrentNode({ node, path, treeIndex });
                      handleCreateModalOpen();
                    }}
                  />,
                  <EditIcon
                    sx={{
                      color: "#1976d2",
                      "&:hover": { cursor: "pointer", color: "#1565c0" },
                    }}
                    onClick={() => {
                      setCurrentNode({ node, path, treeIndex });
                      handleEditModalOpen();
                    }}
                  />,
                  <RemoveCircleIcon
                    sx={{
                      color: "#ef5350",
                      "&:hover": { cursor: "pointer", color: "#d32f2f " },
                    }}
                    onClick={() => {
                      setDeleteNodeDetails({
                        treeData: myTreeData,
                        path: path,
                        getNodeKey,
                      });
                      handleModalOpen();
                    }}
                  />,
                ]
              : [
                  <AddCircleIcon
                    sx={{
                      color: "#1976d2",
                      "&:hover": { cursor: "pointer", color: "#1565c0" },
                    }}
                    onClick={() => {
                      setCurrentNode({ node, path, treeIndex });
                      handleCreateModalOpen();
                    }}
                  />,

                  <EditIcon
                    sx={{
                      color: "#1976d2",
                      "&:hover": { cursor: "pointer", color: "#1565c0" },
                    }}
                    onClick={() => {
                      setCurrentNode({ node, path, treeIndex });
                      handleEditModalOpen();
                    }}
                  />,
                ],
        })}
      />

      <RemoveModalNode
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        handleNodeDelete={handleNodeDelete}
      />

      <EditModalNode
        editModalOpen={editModalOpen}
        handleEditModalClose={handleEditModalClose}
        currentNodeInfo={currentNode}
        changeNodeAtPath={changeNodeAtPath}
        getNodeKey={getNodeKey}
        savedData={savedData}
        moduleFields={fieldNames}
        leadFields={leadFields}
        myTreeData={myTreeData}
        moduleName={moduleName}
      />

      {/* moduleFields={ getInitialFilteredOptions(fieldNames, myTreeData[0], [], 0)} */}
      {/* moduleFields={ getInitialFilteredOptions(fieldNames, myTreeData[0], [], 0)} */}
      <CreateModalNode
        createModalOpen={createModalOpen}
        handleCreateModalClose={handleCreateModalClose}
        currentNodeInfo={currentNode}
        addNodeUnderParent={addNodeUnderParent}
        getNodeKey={getNodeKey}
        savedData={savedData}
        moduleFields={fieldNames}
        leadFields={leadFields}
        myTreeData={myTreeData}
        moduleName={moduleName}
      />

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          This folder should be at least child folder of root folder!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MySortableTree;

/**
 * <MyShortableTree moduleName="Contacts" saveData={saveData} />
 *
 */
